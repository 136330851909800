/* istanbul ignore file */
import React from 'react';
import { Flex, FlexBehavior, Tag, TagColor, TagSize } from 'react-magma-dom';

interface TagWithFlexProps {
  color?: TagColor;
  size?: TagSize;
  tagLabel: string;
  flexBehavior?: FlexBehavior;
}

export const TagWithFlex: React.FC<TagWithFlexProps> = ({
  color = TagColor.primary,
  size = TagSize.small,
  flexBehavior = FlexBehavior.item,
  tagLabel,
}) => {
  return (
    <Flex behavior={flexBehavior}>
      <Tag color={color} size={size}>
        {tagLabel}
      </Tag>
    </Flex>
  );
};
