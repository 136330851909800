import React from 'react';
import { Flex, FlexBehavior, magma, Paragraph, TypographyContextVariant, TypographyVisualStyle } from 'react-magma-dom';

const defaultTitleStyle: React.CSSProperties = {
  fontWeight: 600,
  color: magma.colors.neutral,
  margin: '0 0 4px 0',
};
const defaultContentStyle: React.CSSProperties = {
  color: magma.colors.neutral03,
  whiteSpace: 'pre-line',
};

interface InformationParagraphProps {
  title: string;
  content: string;
  titleStyle?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
}

export const InformationParagraph = (props: InformationParagraphProps): JSX.Element => {
  const { title, content, titleStyle, contentStyle } = props;

  return (
    <Flex behavior={FlexBehavior.item}>
      <Paragraph
        contextVariant={TypographyContextVariant.expressive}
        visualStyle={TypographyVisualStyle.bodySmall}
        style={titleStyle ? titleStyle : defaultTitleStyle}
      >
        {title}
      </Paragraph>
      <Paragraph
        noMargins
        contextVariant={TypographyContextVariant.expressive}
        visualStyle={TypographyVisualStyle.bodySmall}
        style={contentStyle ? contentStyle : defaultContentStyle}
      >
        {content}
      </Paragraph>
    </Flex>
  );
};
