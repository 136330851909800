import React, { useEffect, useState } from 'react';
import {
  ButtonVariant,
  Flex,
  FlexBehavior,
  IconButton,
  Input,
  InputType,
  Tooltip,
  TooltipPosition,
} from 'react-magma-dom';
import { InfoIcon } from 'react-magma-icons';
import { Theme } from 'wa-ui-components';
import { useTranslation } from 'react-i18next';
import { BulkEditType, EditType } from 'context/ExtensionsContextProvider/types';
import { allowOnlyDecimalInput, validateStudentSubmissionsInput } from 'utils/validation/validationHelpers';
import { InformationParagraph } from 'facultyComponents/extensions/edit/InformationParagraph';

const inputContainerStyle: React.CSSProperties = {
  width: '8rem',
  minHeight: '8rem',
  whiteSpace: 'pre',
  boxSizing: 'border-box',
};

interface IAdditionalSubmissionsProps {
  additionalStudentSubmissions?: number;
  studentAllowedSubmissions?: number;
  assignmentSubmissions: number;
  onChangeAdditionalStudentSubmissions: (submissions?: number) => void;
  isUnlimitedSubmissions?: boolean;
  isQuestionLevelSubmissionOverrideAvailable?: boolean;
  isAllQuestionsHaveOverriddenSubmissions?: boolean;
  editType: EditType;
  bulkEditType?: BulkEditType;
}

const initialValidationState = {
  error: false,
  message: '',
};

export const AdditionalSubmissions = (props: IAdditionalSubmissionsProps): JSX.Element => {
  const {
    additionalStudentSubmissions,
    studentAllowedSubmissions,
    assignmentSubmissions,
    onChangeAdditionalStudentSubmissions,
    isUnlimitedSubmissions,
    isQuestionLevelSubmissionOverrideAvailable,
    isAllQuestionsHaveOverriddenSubmissions,
    editType,
    bulkEditType,
  } = props;

  const { t } = useTranslation();
  const [isUnsavedChanges, setIsUnsavedChanges] = useState(false);
  const [unsavedStudentSubmissions, setUnsavedStudentSubmissions] = useState(studentAllowedSubmissions);
  const [validationError, setValidationError] = useState(initialValidationState);

  useEffect(() => {
    if (additionalStudentSubmissions === undefined) {
      setValidationError(initialValidationState);
      setIsUnsavedChanges(false);
      setUnsavedStudentSubmissions(studentAllowedSubmissions);
    }
  }, [additionalStudentSubmissions]);

  const handleAdditionalStudentSubmissionsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setValidationError(validateStudentSubmissionsInput(value));
    const parsedValue = value ? Number(value) : undefined;
    onChangeAdditionalStudentSubmissions(parsedValue);
  };

  const handleBlur = () => {
    if (additionalStudentSubmissions && !studentAllowedSubmissions && !validationError.error) {
      setIsUnsavedChanges(true);
      setUnsavedStudentSubmissions(assignmentSubmissions + additionalStudentSubmissions);
    } else if (additionalStudentSubmissions && studentAllowedSubmissions && !validationError.error) {
      setIsUnsavedChanges(true);
      setUnsavedStudentSubmissions(studentAllowedSubmissions + additionalStudentSubmissions);
    }
  };

  const getHelperMessage = (): string => {
    let helperMessage = '';

    if (editType === EditType.EDIT) {
      helperMessage = t('EXTENSIONS.ADD_SUBMISSIONS.HELPER_MESSAGE.ASSIGNMENT_DEFAULT', {
        assignmentSubmissions: assignmentSubmissions,
      });
      if (isUnsavedChanges) {
        helperMessage = t('EXTENSIONS.ADD_SUBMISSIONS.HELPER_MESSAGE.EDIT_VIEW.UNSAVED_CHANGES', {
          assignmentSubmissions: assignmentSubmissions,
          unsavedStudentSubmissions: unsavedStudentSubmissions,
        });
      }
    } else if (editType === EditType.BULK_EDIT) {
      if (bulkEditType === BulkEditType.ASSIGNMENT && isUnsavedChanges) {
        helperMessage = t('EXTENSIONS.ADD_SUBMISSIONS.HELPER_MESSAGE.BULK_EDIT_VIEW.ASSIGNMENTS.UNSAVED_CHANGES');
      } else if (bulkEditType === BulkEditType.STUDENT && isUnsavedChanges) {
        helperMessage = t('EXTENSIONS.ADD_SUBMISSIONS.HELPER_MESSAGE.BULK_EDIT_VIEW.STUDENTS.UNSAVED_CHANGES', {
          assignmentSubmissions: assignmentSubmissions,
          unsavedStudentSubmissions: unsavedStudentSubmissions,
        });
      }
    }
    return helperMessage;
  };

  const renderContent = () => {
    if (isUnlimitedSubmissions) {
      return (
        <InformationParagraph
          title={t('EXTENSIONS.ADD_SUBMISSIONS.LABEL')}
          content={t('EXTENSIONS.ADD_SUBMISSIONS.HELPER_MESSAGE.UNLIMITED_SUBMISSIONS')}
        />
      );
    } else if (isAllQuestionsHaveOverriddenSubmissions) {
      return (
        <InformationParagraph
          title={t('EXTENSIONS.ADD_SUBMISSIONS.LABEL')}
          content={t('EXTENSIONS.ADD_SUBMISSIONS.HELPER_MESSAGE.ALL_QUESTIONS_OVERRIDDEN')}
        />
      );
    } else {
      return (
        <Flex behavior={FlexBehavior.container}>
          <Input
            testId={'addSubmissionsField'}
            labelText={t('EXTENSIONS.ADD_SUBMISSIONS.LABEL')}
            helperMessage={getHelperMessage()}
            value={additionalStudentSubmissions ?? ''}
            onChange={handleAdditionalStudentSubmissionsChange}
            onBeforeInput={allowOnlyDecimalInput}
            onBlur={handleBlur}
            type={InputType.number}
            containerStyle={inputContainerStyle}
            min={1}
            max={10}
            errorMessage={validationError.error ? validationError.message : null}
          />
          {isQuestionLevelSubmissionOverrideAvailable && (
            <Tooltip
              content={t('EXTENSIONS.ADD_SUBMISSIONS.TOOLTIP_MESSAGE')}
              position={TooltipPosition.top}
              tooltipStyle={{ whiteSpace: 'pre-line', textAlign: 'center' }}
            >
              <IconButton
                style={{ marginTop: '2rem' }}
                variant={ButtonVariant.link}
                aria-label={t('EXTENSIONS.ADD_SUBMISSIONS.TOOLTIP_MESSAGE') || 'infoIcon'}
                icon={<InfoIcon color={Theme.colors.primaryBlue} />}
              ></IconButton>
            </Tooltip>
          )}
        </Flex>
      );
    }
  };

  return <>{renderContent()}</>;
};
