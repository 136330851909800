/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Header } from 'facultyComponents/extensions/header/Header';
import { LoaderComponent } from 'wa-ui-components';
import { getAssignmentLog, getHeaderAssignmentInfo, getHeaderStudentInfo } from './apiHelpers';
import {
  IHeaderAssignmentInfo,
  IHeaderStudentInfo,
  IAssignmentLog,
  RestrictionLevel,
} from 'context/ExtensionsContextProvider/types';
import { EditContainer } from 'facultyComponents/extensions/edit/EditContainer';

export const StudentAssignmentPage: React.FC = () => {
  const { userId, deploymentId } = useParams<{ userId: string; deploymentId: string }>();
  const [assignmentInfo, setAssignmentInfo] = useState<IHeaderAssignmentInfo>();
  const [studentInfo, setStudentInfo] = useState<IHeaderStudentInfo>();
  const [assignmentLog, setAssignmentLog] = useState<IAssignmentLog>({
    assignmentAvailableDate: '',
    studentLastSubmissionDate: '',
    assignmentDownloaded: '',
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!deploymentId || !userId) return;
    const fetchStudentInfo = async () => {
      try {
        const response = await getHeaderStudentInfo(userId, RestrictionLevel.DEPLOYMENT, deploymentId);
        setStudentInfo(response.data.result);
      } catch (error) {
        console.error('Failed to fetch student extensions:', error);
      }
    };

    const fetchAssignmentInfo = async () => {
      try {
        const response = await getHeaderAssignmentInfo(deploymentId);
        setAssignmentInfo(response.data.result);
      } catch (error) {
        console.error('Failed to fetch assignment restrictions:', error);
      }
    };

    const fetchAssignmentLog = async () => {
      try {
        const response = await getAssignmentLog(userId, deploymentId, true);
        setAssignmentLog(response.data.result);
      } catch (error) {
        console.error('Failed to fetch assignment log info:', error);
      }
    };

    Promise.all([fetchStudentInfo(), fetchAssignmentInfo(), fetchAssignmentLog()]).finally(() => {
      setIsLoading(false);
    });
  }, [deploymentId, userId]);

  return (
    <>
      {isLoading ? (
        <LoaderComponent />
      ) : (
        <>
          <Header assignment={assignmentInfo} student={studentInfo} />
          <EditContainer
            userId={userId}
            deploymentId={deploymentId}
            isTimedAssignment={true}
            assignmentLog={assignmentLog}
          />
        </>
      )}
    </>
  );
};
