/* istanbul ignore file */
import styled from '@emotion/styled';
import React from 'react';
import { EditExtensions } from 'facultyComponents/extensions/edit/EditExtensions';
import { StudentLog } from 'facultyComponents/extensions/edit/StudentLog';
import { IAssignmentLog } from 'context/ExtensionsContextProvider/types';

const Container = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 1rem; // Adjust the gap between the components
  align-items: stretch; // Ensures both components stretch to the same height
  margin: 0 auto; // Center the container
`;

interface IEditContainerProps {
  userId: string;
  deploymentId: string;
  isTimedAssignment: boolean;
  assignmentLog: IAssignmentLog;
}

export const EditContainer: React.FC<IEditContainerProps> = (props: IEditContainerProps) => {
  const { userId, deploymentId, isTimedAssignment, assignmentLog } = props;

  return (
    <Container>
      <EditExtensions isTimedAssignment={isTimedAssignment} />
      <StudentLog userId={userId} deploymentId={deploymentId} assignmentLog={assignmentLog} />
    </Container>
  );
};
