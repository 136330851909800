/* istanbul ignore file */
import React from 'react';
import {
  EnumTooltipPosition,
  Flex,
  FlexAlignItems,
  FlexBehavior,
  FlexJustify,
  FlexWrap,
  Tooltip,
} from 'react-magma-dom';
import { MoreTimeIcon } from 'react-magma-icons';
import { Theme } from 'wa-ui-components';
import { useTranslation } from 'react-i18next';

interface IProps {
  grantedTime: number;
  remainingTime: number;
}

export const RemainingTime: React.FC<IProps> = (props): JSX.Element => {
  const { grantedTime, remainingTime } = props;
  const { t } = useTranslation();

  const remainingTimeMinutes = remainingTime / 60;

  if (grantedTime === 0) {
    return <Flex behavior={FlexBehavior.item}>{t('EXTENSIONS.TABLES.MIN_LEFT', { min: remainingTimeMinutes })}</Flex>;
  } else {
    return (
      <Flex
        behavior={FlexBehavior.container}
        alignItems={FlexAlignItems.center}
        justify={FlexJustify.flexStart}
        spacing={0.5}
        wrap={FlexWrap.nowrap}
      >
        <Flex behavior={FlexBehavior.item}>
          <Tooltip position={EnumTooltipPosition.left} content={t('EXTENSIONS.TABLES.TIME_TOOLTIP')}>
            <div style={{ display: 'inline-flex' }}>
              <MoreTimeIcon size={24} color={Theme.colors.darkGrey} style={{ display: 'block' }} />
            </div>
          </Tooltip>
        </Flex>
        <Flex behavior={FlexBehavior.item}>{t('EXTENSIONS.TABLES.MIN_LEFT', { min: remainingTimeMinutes })}</Flex>
      </Flex>
    );
  }
};
