/* istanbul ignore file */
import React from 'react';
import { isNumeric } from 'utils/utils';

import { ScoreStatus } from 'context/ExtensionsContextProvider/types';
import { Flex, FlexBehavior, FlexDirection } from 'react-magma-dom';
import { useTranslation } from 'react-i18next';

interface IProps {
  totalPoints: number;
  awardedPoints: number | string;
}

const calculatePercentage = (total: number, score: number) => {
  return Math.round((score / total) * 100);
};

export const PointsDisplay: React.FC<IProps> = (props): JSX.Element => {
  const { totalPoints, awardedPoints } = props;
  const { t } = useTranslation();

  const formattedScores = (total: number, score: number) => {
    return t('EXTENSIONS.STUDENT_LOG.POINTS_WITH_PERCENTAGE', {
      score: score,
      total: total,
      percentage: calculatePercentage(total, score),
    });
  };

  if (isNumeric(awardedPoints)) {
    return <Flex behavior={FlexBehavior.item}>{formattedScores(totalPoints, Number(awardedPoints))}</Flex>;
  }

  const studentScore = formattedScores(totalPoints, 0);

  switch (awardedPoints) {
    case ScoreStatus.NEVER_SUBMITTED:
      return (
        <Flex behavior={FlexBehavior.container} direction={FlexDirection.column} spacing={0.5}>
          <Flex behavior={FlexBehavior.item}>{t('EXTENSIONS.STUDENT_LOG.NEVER_SUBMITTED')}</Flex>
          <Flex behavior={FlexBehavior.item}>{studentScore}</Flex>
        </Flex>
      );

    case ScoreStatus.NEVER_DOWNLOADED:
      return (
        <Flex behavior={FlexBehavior.container} direction={FlexDirection.column} spacing={0.5}>
          <Flex behavior={FlexBehavior.item}>{t('EXTENSIONS.STUDENT_LOG.NEVER_DOWNLOADED')}</Flex>
          <Flex behavior={FlexBehavior.item}>{studentScore}</Flex>
        </Flex>
      );

    default:
      return (
        <Flex behavior={FlexBehavior.container} direction={FlexDirection.column} spacing={0.5}>
          <Flex behavior={FlexBehavior.item}>{awardedPoints}</Flex>
          <Flex behavior={FlexBehavior.item}>{studentScore}</Flex>
        </Flex>
      );
  }
};
