import { t } from 'i18next';
import React from 'react';

export const checkMinMax = (
  value: number,
  min: number,
  max: number,
  minMessage = '',
  maxMessage = ''
): { value: boolean; message: string } => {
  return {
    value: value < min || value > max,
    message: value < min ? minMessage : maxMessage,
  };
};

export const checkIsWholeNumber = (value: string, message: string): { value: boolean; message: string } => {
  const numberLength = value.split('.').length;
  return { value: numberLength > 1, message: numberLength > 1 ? message : '' };
};
export const checkIsDecimalOnly = (value: string, message: string): { value: boolean; message: string } => {
  const amountOfNumberAfterDot = value.split('.')[1] ? value.split('.')[1].length : 1;
  return { value: amountOfNumberAfterDot > 1, message: amountOfNumberAfterDot > 1 ? message : '' };
};

export const validateTimeAccommodationInput = (input: string): { error: boolean; message: string } => {
  return validateInputRange(input, 1, 1000, t('EXTENSIONS.ADD_TIME.ERROR.NOT_IN_RANGE'));
};

export const allowOnlyDecimalInput = (event: React.FormEvent<HTMLInputElement>): void => {
  const input = (event.nativeEvent as InputEvent).data;
  const containsOnlyDecimal = /^\d+$/;

  if (input && !containsOnlyDecimal.test(input)) {
    event.preventDefault();
  }
};

export const allowOnlyPatternInput = (event: React.FormEvent<HTMLInputElement>): void => {
  const input = (event.nativeEvent as InputEvent).data;
  const containsOnlyDecimal = /^[\d/]+$/;

  if (input && !containsOnlyDecimal.test(input)) {
    event.preventDefault();
  }
};

const hasLeadingZeros = (input: string) => {
  return /^0\d+/.test(input);
};

export const validateExtendedDueDateInputRange = (
  classDueDateTime: Date,
  studentExtendedDueDate?: Date,
  studentExtendedDueTime?: string
) => {
  let message = '';
  let error = false;

  const formattedClassDueDateTimeForDisplay = getFormattedDate(classDueDateTime);

  if (studentExtendedDueDate && !studentExtendedDueTime) {
    const classDueDateWithoutTime = getDateWithoutTime(classDueDateTime);
    if (studentExtendedDueDate < classDueDateWithoutTime) {
      error = true;
      message = t('EXTENSIONS.EXTENDED_DUE_DATE.ERROR.NOT_IN_RANGE', {
        classDueDate: formattedClassDueDateTimeForDisplay,
      });
    }
  } else if (studentExtendedDueDate && studentExtendedDueTime) {
    const studentExtendedDueDateTime = combineDateAndTime(studentExtendedDueDate, studentExtendedDueTime);

    if (studentExtendedDueDateTime <= classDueDateTime) {
      error = true;
      message = t('EXTENSIONS.EXTENDED_DUE_DATE.ERROR.NOT_IN_RANGE', {
        classDueDate: formattedClassDueDateTimeForDisplay,
      });
    }
  }
  return { error, message };
};

export const isValidDateFormat = (date?: string): boolean => {
  const dateRegex = /^(0[1-9]|1[0-2]|[1-9])\/(0[1-9]|1\d|2\d|3[01]|[1-9])\/(19|20)\d{2}$/;
  return date ? dateRegex.test(date) : true;
};

export const isValidTimeFormat = (time?: string) => {
  const timeRegex = /^(0[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/;
  return time ? timeRegex.test(time) : true;
};

export const getDateWithoutTime = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export const getDueDateTimeFromTimeRemaining = (timeRemaining?: number): null | string => {
  if (!timeRemaining) return null;
  const dueDateTime = new Date(Date.now() + timeRemaining * 1000);

  return getFormattedDate(dueDateTime);
};

export const getFormattedDate = (date: Date): string => {
  const locale = navigator.language;

  return new Intl.DateTimeFormat(locale, {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZoneName: 'short',
  })
    .format(date)
    .replace(',', '');
};

export const getDateWithTimezoneOffset = (dateUTCString: string) => {
  const dateInCorrectTimezone = new Date(dateUTCString.replace(/-/g, '/'));

  dateInCorrectTimezone.setTime(
    dateInCorrectTimezone.getTime() - dateInCorrectTimezone.getTimezoneOffset() * 60 * 1000
  );
  return dateInCorrectTimezone;
};

const combineDateAndTime = (date: Date, time: string): Date => {
  const { adjustedHours, minutes } = parseTimeTo24hFormat(time);
  const combinedDate = new Date(date);
  combinedDate.setHours(adjustedHours, minutes, 0);
  return combinedDate;
};

const parseTimeTo24hFormat = (time: string) => {
  const [timePart, period] = time.split(' ');
  const [hours, minutes] = timePart.split(':').map(Number);
  let adjustedHours = hours;

  if (period === 'PM' && hours !== 12) {
    adjustedHours = hours + 12;
  } else if (period === 'AM' && hours === 12) {
    adjustedHours = 0;
  }
  return { adjustedHours, minutes };
};

export const validateStudentSubmissionsInput = (input: string): { error: boolean; message: string } => {
  return validateInputRange(input, 1, 10, t('EXTENSIONS.ADD_SUBMISSIONS.ERROR.NOT_IN_RANGE'));
};

export const validatePenaltyInput = (
  input: string,
  minValue: number,
  maxValue: number
): { error: boolean; message: string } => {
  return validateInputRange(
    input,
    minValue,
    maxValue,
    t('EXTENSIONS.PENALTY.ERROR.NOT_IN_RANGE', { min: minValue, max: maxValue })
  );
};

const validateInputRange = (input: string, min: number, max: number, errorMessage: string) => {
  let message = '';
  let error = false;

  if (input === '') return { error, message };

  if (hasLeadingZeros(input)) {
    error = true;
    message = errorMessage;
  }

  const parsedValue = parseInt(input);
  if (parsedValue < min || parsedValue > max) {
    error = true;
    message = errorMessage;
  }

  return { error, message };
};
