/* istanbul ignore file */
import React, { useState } from 'react';
import {
  Checkbox,
  IndeterminateCheckbox,
  IndeterminateCheckboxStatus,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'react-magma-dom';
import styled from '@emotion/styled';
import { TagWithFlex } from 'facultyComponents/extensions/helpers/TagWithFlex';
import { PointsDisplay } from 'facultyComponents/extensions/tables/PointsDisplay';
import { isDate } from 'utils/utils';
import { getFormattedDate } from 'utils/validation/validationHelpers';
import { RemainingTime } from 'facultyComponents/extensions/tables/RemainingTime';
import { useTranslation } from 'react-i18next';

// stubs for testing
const students = [
  {
    assignmentBaseTime: '60',
    assignmentTimed: true,
    assignmentTotal: 15,
    deploymentId: '35558848',
    studentFullname: 'dtrest, atrest',
    studentHasSeenKeys: false,
    studentId: '10162687',
    studentScore: 'ND',
    studentTimeRemaining: 3600,
    studentTimeGranted: 0,
  },
  {
    assignmentBaseTime: '60',
    assignmentTimed: true,
    assignmentTotal: 15,
    deploymentId: '35558848',
    studentExtendedDueDate: '2025-04-24 14:05:00',
    studentFullname: 'gtrestl, gtrest',
    studentHasSeenKeys: false,
    studentId: '10162688',
    studentScore: 'ND',
    studentTimeRemaining: 3600,
    studentTimeGranted: 45,
  },
  {
    assignmentBaseTime: '60',
    assignmentTimed: true,
    assignmentTotal: 15,
    deploymentId: '35558848',
    studentFullname: 'tres2, tres2',
    studentHasSeenKeys: true,
    studentId: '10162081',
    studentScore: 'ND',
    studentTimeRemaining: 3600,
    studentTimeGranted: 0,
  },
  {
    assignmentBaseTime: '60',
    assignmentTimed: true,
    assignmentTotal: 15,
    deploymentId: '35558848',
    studentExtendedDueDate: '2025-03-30 14:11:00',
    studentFullname: 'trest, trest',
    studentHasSeenKeys: true,
    studentId: '10162080',
    studentScore: 10,
    studentTimeRemaining: 0,
    studentTimeGranted: 10,
  },
  {
    assignmentBaseTime: '60',
    assignmentTimed: true,
    assignmentTotal: 15,
    deploymentId: '35558848',
    studentExtendedDueDate: '2025-06-24 14:05:00',
    studentFullname: 'trest10, trest10',
    studentHasSeenKeys: false,
    studentId: '10162622',
    studentScore: 'NS',
    studentTimeRemaining: 0,
    studentTimeGranted: 0,
  },
  {
    assignmentBaseTime: '60',
    assignmentTimed: true,
    assignmentTotal: 15,
    deploymentId: '35558848',
    studentFullname: 'ymakarenko44334',
    studentHasSeenKeys: true,
    studentId: '10161970',
    studentScore: 11,
    studentTimeRemaining: 0,
    studentTimeGranted: 30,
  },
  {
    assignmentBaseTime: '60',
    assignmentTimed: true,
    assignmentTotal: 15,
    deploymentId: '35558848',
    studentFullname: 'johndoe123',
    studentHasSeenKeys: false,
    studentId: '10162701',
    studentScore: 13,
    studentTimeRemaining: 1800,
    studentTimeGranted: 15,
  },
  {
    assignmentBaseTime: '60',
    assignmentTimed: true,
    assignmentTotal: 15,
    deploymentId: '35558848',
    studentExtendedDueDate: '2025-05-15 10:00:00',
    studentFullname: 'emilyrose789',
    studentHasSeenKeys: true,
    studentId: '10162702',
    studentScore: 8,
    studentTimeRemaining: 900,
    studentTimeGranted: 60,
  },
  {
    assignmentBaseTime: '60',
    assignmentTimed: true,
    assignmentTotal: 15,
    deploymentId: '35558848',
    studentFullname: 'davidlee456',
    studentHasSeenKeys: false,
    studentId: '10162703',
    studentScore: 'ND',
    studentTimeRemaining: 2700,
    studentTimeGranted: 20,
  },
  {
    assignmentBaseTime: '60',
    assignmentTimed: true,
    assignmentTotal: 15,
    deploymentId: '35558848',
    studentExtendedDueDate: '2025-04-30 16:30:00',
    studentFullname: 'sarahjones987',
    studentHasSeenKeys: true,
    studentId: '10162704',
    studentScore: 14,
    studentTimeRemaining: 0,
    studentTimeGranted: 50,
  },
  {
    assignmentBaseTime: '60',
    assignmentTimed: true,
    assignmentTotal: 15,
    deploymentId: '35558848',
    studentFullname: 'michaelbrown222',
    studentHasSeenKeys: false,
    studentId: '10162705',
    studentScore: 'NS',
    studentTimeRemaining: 1200,
    studentTimeGranted: 35,
  },
  {
    assignmentBaseTime: '60',
    assignmentTimed: true,
    assignmentTotal: 15,
    deploymentId: '35558848',
    studentFullname: 'lindawilliams555',
    studentHasSeenKeys: true,
    studentId: '10162706',
    studentScore: 9,
    studentTimeRemaining: 0,
    studentTimeGranted: 25,
  },
];

interface StylesProps {
  topIndentation: number;
}

const STable = styled(Table)`
  border: 2px solid ${({ theme }) => theme.colors.greyAccent};
  width: 100%;
  table-layout: auto;
`;

const STableHead = styled(TableHead)<StylesProps>`
  background: ${({ theme }) => theme.colors.lightGrey};
  position: sticky;
  top: ${({ topIndentation }) => topIndentation}px;
  z-index: 1;
`;

const STableCell = styled(TableCell)`
  text-align: left;
  vertical-align: middle;
`;

const STableCellTitle = styled(STableCell)`
  font-weight: bold;
`;

interface IProps {
  isTimedAssignment: boolean;
  topIndentation: number;
}

const StudentTable: React.FC<IProps> = (props): JSX.Element => {
  const { isTimedAssignment, topIndentation } = props;
  const { t } = useTranslation();
  const [selectedStudents, setSelectedStudents] = useState<string[]>([]);

  const handleHeaderCheckboxChange = (isChecked: boolean) => {
    setSelectedStudents(isChecked ? students.map(student => student.studentId) : []);
  };

  const handleStudentCheckboxChange = (studentId: string, isChecked: boolean) => {
    setSelectedStudents(prev => (isChecked ? [...prev, studentId] : prev.filter(id => id !== studentId)));
  };

  const checkboxStatus = (() => {
    if (students.length === 0) return IndeterminateCheckboxStatus.unchecked;
    if (selectedStudents.length === students.length) return IndeterminateCheckboxStatus.checked;
    if (selectedStudents.length > 0) return IndeterminateCheckboxStatus.indeterminate;
    return IndeterminateCheckboxStatus.unchecked;
  })();

  return (
    <STable>
      <STableHead topIndentation={topIndentation}>
        <TableRow>
          <STableCellTitle width="25%">
            <IndeterminateCheckbox
              labelText={t('EXTENSIONS.TABLES.TITLE_STUDENT')}
              onChange={e => handleHeaderCheckboxChange(e.target.checked)}
              status={checkboxStatus}
            />
          </STableCellTitle>
          <STableCellTitle width="25%">{t('EXTENSIONS.TABLES.TITLE_POINTS')}</STableCellTitle>
          <STableCellTitle width="25%">{t('EXTENSIONS.TABLES.TITLE_EXTENDED_DUE_DATE')}</STableCellTitle>
          <STableCellTitle width="25%" style={{ display: isTimedAssignment ? 'table-cell' : 'none' }}>
            {t('EXTENSIONS.TABLES.TITLE_REMAINING_TIME')}
          </STableCellTitle>
        </TableRow>
      </STableHead>
      <TableBody>
        {students.map((student, index) => (
          <TableRow key={index}>
            <STableCell width="25%">
              <Checkbox
                labelText={
                  <div>
                    <div>{student.studentFullname}</div>
                    {student.studentHasSeenKeys && (
                      <div style={{ marginTop: '8px' }}>
                        <TagWithFlex tagLabel={t('EXTENSIONS.STUDENT_LOG.SHOWN_ANSWER_KEY')} />
                      </div>
                    )}
                  </div>
                }
                checked={selectedStudents.includes(student.studentId)}
                onChange={e => handleStudentCheckboxChange(student.studentId, e.target.checked)}
              />
            </STableCell>
            <STableCell width="25%">
              <PointsDisplay totalPoints={student.assignmentTotal} awardedPoints={student.studentScore} />
            </STableCell>
            <STableCell width="25%">
              {isDate(student.studentExtendedDueDate)
                ? getFormattedDate(new Date(student.studentExtendedDueDate || ''))
                : ''}
            </STableCell>
            <STableCell width="25%" style={{ display: isTimedAssignment ? 'table-cell' : 'none' }}>
              <RemainingTime grantedTime={student.studentTimeGranted} remainingTime={student.studentTimeRemaining} />
            </STableCell>
          </TableRow>
        ))}
      </TableBody>
    </STable>
  );
};

export default StudentTable;
