/* istanbul ignore file */
import styled from '@emotion/styled';
import { Card, Flex, FlexAlignItems, FlexBehavior, FlexJustify, FlexWrap, Hyperlink } from 'react-magma-dom';
import React from 'react';
import { ExternalLinkIcon } from 'react-magma-icons';
import { IAssignmentLog } from 'context/ExtensionsContextProvider/types';
import { isDate, isNotNullish } from 'utils/utils';
import { useTranslation } from 'react-i18next';
import { getFormattedDate } from 'utils/validation/validationHelpers';

const StudentLogCard = styled(Card)`
  padding: 1.5rem;
  border-radius: 0.25rem;
  background: ${({ theme }) => theme.colors.lightGrey};
  box-sizing: border-box;
  margin-right: 2em;
`;

const LogTitle = styled.h5`
  margin: 0 0 1.2rem 0;
  font-size: 1rem;
`;

const LogItem = styled.p`
  margin: 0.5rem 0;
  font-size: 0.875rem;
  flex: 1;
`;

const LinkWrapper = styled.div`
  display: inline-block;
  margin-top: 0.5rem;

  a {
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-size: 0.875rem;
    background-color: transparent;
    border: none;
    font: ${({ theme }) => theme.OpenSans};
    font-weight: 400;
    text-decoration: none;
    cursor: pointer;
  }
`;

interface StudentLogProps {
  userId: string;
  deploymentId: string;
  assignmentLog: IAssignmentLog;
}

export const StudentLog = (props: StudentLogProps): JSX.Element => {
  const { userId, deploymentId, assignmentLog } = props;
  const { t } = useTranslation();

  return (
    <StudentLogCard>
      <LogTitle>{t('EXTENSIONS.STUDENT_LOG.TITLE')}</LogTitle>
      {isNotNullish(assignmentLog.studentScore) && (
        <LogItem>
          <strong>{t('EXTENSIONS.STUDENT_LOG.POINTS')}:</strong> <br />
          {t('EXTENSIONS.STUDENT_LOG.POINTS_WITH_TOTAL', {
            score: assignmentLog.studentScore,
            total: assignmentLog.assignmentTotal,
          })}
        </LogItem>
      )}
      <LogItem>
        <strong>{t('EXTENSIONS.STUDENT_LOG.AVAILABLE_DATE')}:</strong> <br />
        {getFormattedDate(new Date(assignmentLog.assignmentAvailableDate))}
      </LogItem>
      <LogItem>
        <strong>{t('EXTENSIONS.STUDENT_LOG.DOWNLOADED')}:</strong> <br />
        {isDate(assignmentLog.assignmentDownloaded)
          ? getFormattedDate(new Date(assignmentLog.assignmentDownloaded))
          : t('EXTENSIONS.STUDENT_LOG.NEVER_DOWNLOADED')}
      </LogItem>
      <LogItem>
        <strong>{t('EXTENSIONS.STUDENT_LOG.LAST_SUBMISSION')}:</strong> <br />
        {isDate(assignmentLog.studentLastSubmissionDate)
          ? getFormattedDate(new Date(assignmentLog.studentLastSubmissionDate))
          : t('EXTENSIONS.STUDENT_LOG.NEVER_SUBMITTED')}
      </LogItem>
      <LinkWrapper>
        <Hyperlink
          target="_blank"
          to={`/v4cgi/faculty.pl?action=grades/loginfo&deployment=${deploymentId}&user=${userId}`}
        >
          <Flex
            behavior={FlexBehavior.container}
            alignItems={FlexAlignItems.center} // Align items vertically in the center
            justify={FlexJustify.flexStart}
            spacing={0.5} // Add spacing between text and icon
            wrap={FlexWrap.nowrap}
          >
            <Flex behavior={FlexBehavior.item}>{t('EXTENSIONS.STUDENT_LOG.LOG_INFO')}</Flex>
            <Flex behavior={FlexBehavior.item}>
              <ExternalLinkIcon size={18} style={{ verticalAlign: 'middle' }} /> {/* Align icon vertically */}
            </Flex>
          </Flex>
        </Hyperlink>
      </LinkWrapper>
      <LinkWrapper>
        <Hyperlink target="_blank" to={`/web/Faculty/Assignment-Responses/display?user=${userId}&dep=${deploymentId}`}>
          <Flex
            behavior={FlexBehavior.container}
            alignItems={FlexAlignItems.center} // Align items vertically in the center
            justify={FlexJustify.flexStart}
            spacing={0.5} // Add spacing between text and icon
            wrap={FlexWrap.nowrap}
          >
            <Flex behavior={FlexBehavior.item}>{t('EXTENSIONS.STUDENT_LOG.STUDENT_RESPONSES')}</Flex>
            <Flex behavior={FlexBehavior.item}>
              <ExternalLinkIcon size={18} style={{ verticalAlign: 'middle' }} /> {/* Align icon vertically */}
            </Flex>
          </Flex>
        </Hyperlink>
      </LinkWrapper>
    </StudentLogCard>
  );
};
