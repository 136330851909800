/* istanbul ignore file */
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { IHeaderAssignmentInfo } from 'context/ExtensionsContextProvider/types';
import { getHeaderAssignmentInfo } from './apiHelpers';
import { Header } from 'facultyComponents/extensions/header/Header';
import { LoaderComponent } from 'wa-ui-components';
import StudentTable from 'facultyComponents/extensions/tables/StudentsTable';
import styled from '@emotion/styled';
import { Flex, FlexBehavior, FlexDirection } from 'react-magma-dom';

const PageContainer = styled(Flex)`
  padding: 0 24px;
`;

const StickyHeaderContainer = styled(Flex)`
  position: sticky;
  top: 0;
  background: white;
  z-index: 2;
  padding: 16px 0;
`;

export const AssignmentStudentsPage: React.FC = () => {
  const { deploymentId, sectionId } = useParams<{ deploymentId: string; sectionId: string }>();

  const [isLoading, setLoading] = useState(true);
  const [assignmentInfo, setAssignmentInfo] = useState<IHeaderAssignmentInfo>();
  const [headerHeight, setHeaderHeight] = useState(0);
  const stickyHeaderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!deploymentId) return;
    const fetchAssignmentInfo = async () => {
      try {
        const response = await getHeaderAssignmentInfo(deploymentId);
        setAssignmentInfo(response.data.result);
      } catch (error) {
        console.error('Failed to fetch assignment restrictions:', error);
      }
    };

    fetchAssignmentInfo().finally(() => {
      setLoading(false);
    });
  }, [deploymentId]);

  // this hook for sticky table title
  useEffect(() => {
    if (!isLoading && stickyHeaderRef.current) {
      const measuredHeight = stickyHeaderRef.current.offsetHeight;
      setHeaderHeight(measuredHeight - 1); // Slight adjustment for removing the shadow under the table header.
    }
  }, [isLoading]);

  return (
    <>
      {isLoading ? (
        <LoaderComponent />
      ) : (
        <>
          <PageContainer behavior={FlexBehavior.container} direction={FlexDirection.column}>
            <StickyHeaderContainer
              behavior={FlexBehavior.container}
              direction={FlexDirection.column}
              ref={stickyHeaderRef}
            >
              <Header assignment={assignmentInfo} sectionId={sectionId} />
            </StickyHeaderContainer>
            <StudentTable isTimedAssignment={assignmentInfo?.assignmentTimed || false} topIndentation={headerHeight} />
          </PageContainer>
        </>
      )}
    </>
  );
};
