import React, { useState } from 'react';
import { Flex, FlexBehavior, Input, InputType, Select } from 'react-magma-dom';
import { useTranslation } from 'react-i18next';
import { PenaltyOptions } from 'context/ExtensionsContextProvider/types';
import { allowOnlyDecimalInput, validatePenaltyInput } from 'utils/validation/validationHelpers';

const selectContainerStyle: React.CSSProperties = { width: '12.5rem' };
const selectLabelStyle: React.CSSProperties = { paddingBottom: '1px' };
const inputContainerStyle: React.CSSProperties = { width: '6rem', whiteSpace: 'nowrap' };

interface IPenaltiesProps {
  assignmentTotalPoints: number;
  penaltyOption: string;
  penaltyValue?: number;
  onPenaltyOptionChange: (option: string) => void;
  onPenaltyValueChange: (value?: number) => void;
}

const initialValidationState = {
  error: false,
  message: '',
};

export const Penalties = (props: IPenaltiesProps): JSX.Element => {
  const { assignmentTotalPoints, penaltyOption, penaltyValue, onPenaltyOptionChange, onPenaltyValueChange } = props;
  const { t } = useTranslation();
  const [validationError, setValidationError] = useState(initialValidationState);

  const selectOptions: string[] = Object.values(PenaltyOptions).map(value => t(value));
  const isPointsOptionSelected = penaltyOption === t(PenaltyOptions.POINTS);
  const minValue = 1;
  const maxValue = penaltyOption === t(PenaltyOptions.POINTS) ? assignmentTotalPoints : 100;

  const handleSelectedPenaltyChange = (changes: any) => {
    const selectedValue = changes.selectedItem ?? '';
    onPenaltyOptionChange(selectedValue);
    onPenaltyValueChange(undefined);
    setValidationError(initialValidationState);
  };

  const handlePenaltyValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setValidationError(validatePenaltyInput(value, minValue, maxValue));

    const parsedValue = value ? Number(value) : undefined;
    onPenaltyValueChange(parsedValue);
  };

  return (
    <Flex behavior={FlexBehavior.container} spacing={1}>
      <Select
        testId={'penaltyOptionField'}
        labelText={t('EXTENSIONS.PENALTY.SELECT.LABEL')}
        items={selectOptions}
        placeholder={selectOptions[0]}
        selectedItem={penaltyOption}
        onSelectedItemChange={handleSelectedPenaltyChange}
        containerStyle={selectContainerStyle}
        labelStyle={selectLabelStyle}
      />
      <Input
        testId={'penaltyValueField'}
        labelText={
          isPointsOptionSelected
            ? t('EXTENSIONS.PENALTY.INPUT.POINTS_LABEL')
            : t('EXTENSIONS.PENALTY.INPUT.PERCENTAGE_LABEL')
        }
        value={penaltyValue ?? ''}
        onChange={handlePenaltyValueChange}
        onBeforeInput={allowOnlyDecimalInput}
        type={InputType.number}
        containerStyle={inputContainerStyle}
        min={minValue}
        max={maxValue}
        errorMessage={validationError.error ? validationError.message : null}
      />
    </Flex>
  );
};
