import styled from '@emotion/styled';
import React, { useState } from 'react';
import { Button, ButtonColor, ButtonTextTransform, ButtonType, Card, Form } from 'react-magma-dom';
import { ExtendedDueDate } from './extendedDueDate/ExtendedDueDate';
import { Penalties } from './penalties/Penalties';
import { AdditionalSubmissions } from './additionalSubmissions/AdditionalSubmissions';
import { AdditionalTime } from './additionalTime/AdditionalTime';
import { useTranslation } from 'react-i18next';
import { EditType, IEditFormData, PenaltyOptions } from 'context/ExtensionsContextProvider/types';

const StyledCard = styled(Card)`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1.565rem 1.875rem;
  border-radius: 0.25rem;
  background: ${({ theme }) => theme.colors.lightGrey};
  box-sizing: border-box;
  margin-left: 2em;
  min-height: 100%;
`;

const StyledForm = styled(Form)`
  background: ${({ theme }) => theme.colors.lightGrey};
  h5 {
    margin: 0 0 1.5rem 0;
  }
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5rem;
`;

const LeftPanel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
`;

const RightPanel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const ButtonContainer = styled.div`
  margin-top: 2rem;
`;

interface IEditExtensionsProps {
  isTimedAssignment: boolean;
  isUnlimitedTimeAccommodation?: boolean;
  isUnlimitedSubmissions?: boolean;
  isQuestionLevelSubmissionOverrideAvailable?: boolean;
  isAllQuestionsHaveOverriddenSubmissions?: boolean;
}

export const EditExtensions = (props: IEditExtensionsProps): JSX.Element => {
  const {
    isTimedAssignment,
    isUnlimitedTimeAccommodation,
    isUnlimitedSubmissions,
    isQuestionLevelSubmissionOverrideAvailable,
    isAllQuestionsHaveOverriddenSubmissions,
  } = props;
  const { t } = useTranslation();

  const initialState: IEditFormData = {
    extendedDueDate: undefined,
    extendedDueTime: undefined,
    timeAccommodation: undefined,
    additionalStudentSubmissions: undefined,
    penaltyOption: t(PenaltyOptions.UNEARNED_PERCENTAGE),
    penaltyValue: undefined,
  };

  const [editFormData, setEditFormData] = useState<IEditFormData>(initialState);
  const [resetInput, setResetInput] = useState(false);

  const handleChange = <T extends keyof IEditFormData>(field: T, value: IEditFormData[T]) => {
    setEditFormData(formData => ({
      ...formData,
      [field]: value,
    }));
  };

  const handleReset = () => {
    setEditFormData(initialState);
    setResetInput(prevState => !prevState);
  };

  return (
    <div>
      <StyledCard>
        <StyledForm
          header={t('EXTENSIONS.EDIT_HEADER')}
          headingLevel={5}
          onReset={handleReset}
          actions={
            <ButtonContainer>
              <Button
                type={ButtonType.reset}
                color={ButtonColor.secondary}
                textTransform={ButtonTextTransform.uppercase}
              >
                {t('EXTENSIONS.BUTTON.RESET')}
              </Button>
              <Button color={ButtonColor.primary} textTransform={ButtonTextTransform.uppercase}>
                {t('EXTENSIONS.BUTTON.SAVE')}
              </Button>
            </ButtonContainer>
          }
        >
          <Row>
            <LeftPanel>
              <ExtendedDueDate
                classDueDateTime={'2025-03-10 17:00:00'}
                studentExtendedDueDate={editFormData.extendedDueDate}
                studentExtendedDueTime={editFormData.extendedDueTime}
                onChangeStudentExtendedDueDate={(dueDate?: Date) => handleChange('extendedDueDate', dueDate)}
                onChangeStudentExtendedDueTime={(dueTime?: string) => handleChange('extendedDueTime', dueTime)}
                onReset={resetInput}
              />
              {!isTimedAssignment && (
                <Penalties
                  assignmentTotalPoints={3}
                  penaltyOption={editFormData.penaltyOption}
                  penaltyValue={editFormData.penaltyValue}
                  onPenaltyOptionChange={(option: string) => handleChange('penaltyOption', option)}
                  onPenaltyValueChange={(value?: number) => handleChange('penaltyValue', value)}
                />
              )}
              {isTimedAssignment && (
                <AdditionalSubmissions
                  additionalStudentSubmissions={editFormData.additionalStudentSubmissions}
                  assignmentSubmissions={5}
                  editType={EditType.EDIT}
                  isUnlimitedSubmissions={isUnlimitedSubmissions}
                  isQuestionLevelSubmissionOverrideAvailable={isQuestionLevelSubmissionOverrideAvailable}
                  isAllQuestionsHaveOverriddenSubmissions={isAllQuestionsHaveOverriddenSubmissions}
                  onChangeAdditionalStudentSubmissions={(submissions?: number) =>
                    handleChange('additionalStudentSubmissions', submissions)
                  }
                />
              )}
            </LeftPanel>
            <RightPanel>
              {!isTimedAssignment && (
                <AdditionalSubmissions
                  additionalStudentSubmissions={editFormData.additionalStudentSubmissions}
                  assignmentSubmissions={5}
                  editType={EditType.EDIT}
                  isUnlimitedSubmissions={isUnlimitedSubmissions}
                  isQuestionLevelSubmissionOverrideAvailable={isQuestionLevelSubmissionOverrideAvailable}
                  isAllQuestionsHaveOverriddenSubmissions={isAllQuestionsHaveOverriddenSubmissions}
                  onChangeAdditionalStudentSubmissions={(submissions?: number) =>
                    handleChange('additionalStudentSubmissions', submissions)
                  }
                />
              )}
              {isTimedAssignment && (
                <AdditionalTime
                  timeAccommodation={editFormData.timeAccommodation}
                  onChange={(value?: number) => handleChange('timeAccommodation', value)}
                  editType={EditType.EDIT}
                  baseTime={60}
                  studentTimeRemaining={50}
                  isUnlimitedTimeAccommodation={isUnlimitedTimeAccommodation}
                />
              )}
              {isTimedAssignment && (
                <Penalties
                  assignmentTotalPoints={3}
                  penaltyOption={editFormData.penaltyOption}
                  penaltyValue={editFormData.penaltyValue}
                  onPenaltyOptionChange={(option: string) => handleChange('penaltyOption', option)}
                  onPenaltyValueChange={(value?: number) => handleChange('penaltyValue', value)}
                />
              )}
            </RightPanel>
          </Row>
        </StyledForm>
      </StyledCard>
    </div>
  );
};
