import styled from '@emotion/styled';
import React, { useState } from 'react';
import {
  Badge,
  Button,
  ButtonColor,
  ButtonTextTransform,
  ButtonType,
  Card,
  Flex,
  FlexBehavior,
  FlexDirection,
  Form,
} from 'react-magma-dom';
import { useTranslation } from 'react-i18next';
import { ExtendedDueDate } from './extendedDueDate/ExtendedDueDate';
import { AdditionalTime } from './additionalTime/AdditionalTime';
import { AdditionalSubmissions } from './additionalSubmissions/AdditionalSubmissions';
import { Penalties } from './penalties/Penalties';

import { BulkEditType, EditType, IEditFormData, PenaltyOptions } from 'context/ExtensionsContextProvider/types';

const StyledCard = styled(Card)`
  max-width: 95rem;
  padding: 1.565rem 1.875rem;
  border-radius: 0.25rem;
  background: ${({ theme }) => theme.colors.lightGrey};
  box-sizing: border-box;
`;

const StyledForm = styled(Form)`
  background: ${({ theme }) => theme.colors.lightGrey};
  h5 {
    margin: 0 0 1.5rem 0;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 3.5rem;
  min-height: 9rem;
  margin-bottom: 2rem;
`;

const StyledBadge = styled(Badge)`
  margin-left: 10px;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  border-radius: 50%;
  height: 24px;
  padding-top: 3px;
`;

type BulkEditExtensionsProps = {
  isTimedAssignment: boolean;
  bulkEditType: BulkEditType;
  isUnlimitedTimeAccommodation?: boolean;
  isUnlimitedSubmissions?: boolean;
  isQuestionLevelSubmissionOverrideAvailable?: boolean;
  isAllQuestionsHaveOverriddenSubmissions?: boolean;
};

export const BulkEditExtensions: React.FC<BulkEditExtensionsProps> = (props: BulkEditExtensionsProps) => {
  const { t } = useTranslation();
  const {
    isTimedAssignment,
    bulkEditType,
    isUnlimitedTimeAccommodation,
    isUnlimitedSubmissions,
    isQuestionLevelSubmissionOverrideAvailable,
    isAllQuestionsHaveOverriddenSubmissions,
  } = props;

  const initialState: IEditFormData = {
    extendedDueDate: undefined,
    extendedDueTime: undefined,
    timeAccommodation: undefined,
    additionalStudentSubmissions: undefined,
    penaltyOption: t(PenaltyOptions.UNEARNED_PERCENTAGE),
    penaltyValue: undefined,
  };

  const [bulkEditFormData, setBulkEditFormData] = useState<IEditFormData>(initialState);
  const [resetInput, setResetInput] = useState(false);

  const handleChange = <T extends keyof IEditFormData>(field: T, value: IEditFormData[T]) => {
    setBulkEditFormData(formData => ({
      ...formData,
      [field]: value,
    }));
  };

  const handleReset = () => {
    setBulkEditFormData(initialState);
    setResetInput(prevState => !prevState);
  };
  const selectedStub = 2;

  return (
    <div>
      <StyledCard>
        <StyledForm
          header={t('EXTENSIONS.BULK_EDIT_HEADER')}
          headingLevel={5}
          onReset={handleReset}
          actions={
            <div>
              <Button
                type={ButtonType.reset}
                color={ButtonColor.secondary}
                textTransform={ButtonTextTransform.uppercase}
              >
                {t('EXTENSIONS.BUTTON.RESET')}
              </Button>
              <Button color={ButtonColor.primary} textTransform={ButtonTextTransform.uppercase}>
                {bulkEditType === BulkEditType.STUDENT
                  ? t('EXTENSIONS.BUTTON.SAVE_TO_SELECTED_STUDENTS')
                  : t('EXTENSIONS.BUTTON.SAVE_TO_SELECTED_ASSIGNMENTS')}
                <StyledBadge>{selectedStub}</StyledBadge>
              </Button>
            </div>
          }
        >
          <InputContainer>
            <Flex behavior={FlexBehavior.item} direction={FlexDirection.column}>
              <ExtendedDueDate
                classDueDateTime={'2025-03-10 17:00:00'}
                studentExtendedDueDate={bulkEditFormData.extendedDueDate}
                studentExtendedDueTime={bulkEditFormData.extendedDueTime}
                onChangeStudentExtendedDueDate={(dueDate?: Date) => handleChange('extendedDueDate', dueDate)}
                onChangeStudentExtendedDueTime={(dueTime?: string) => handleChange('extendedDueTime', dueTime)}
                onReset={resetInput}
              ></ExtendedDueDate>
            </Flex>
            {isTimedAssignment && (
              <Flex behavior={FlexBehavior.item} direction={FlexDirection.column}>
                <AdditionalTime
                  timeAccommodation={bulkEditFormData.timeAccommodation}
                  isUnlimitedTimeAccommodation={isUnlimitedTimeAccommodation}
                  onChange={(value?: number) => handleChange('timeAccommodation', value)}
                  editType={EditType.BULK_EDIT}
                  bulkEditType={bulkEditType}
                  baseTime={60}
                  studentTimeRemaining={50}
                />
              </Flex>
            )}
            <Flex behavior={FlexBehavior.item} direction={FlexDirection.column}>
              <AdditionalSubmissions
                additionalStudentSubmissions={bulkEditFormData.additionalStudentSubmissions}
                assignmentSubmissions={5}
                editType={EditType.BULK_EDIT}
                bulkEditType={bulkEditType}
                isUnlimitedSubmissions={isUnlimitedSubmissions}
                isQuestionLevelSubmissionOverrideAvailable={isQuestionLevelSubmissionOverrideAvailable}
                isAllQuestionsHaveOverriddenSubmissions={isAllQuestionsHaveOverriddenSubmissions}
                onChangeAdditionalStudentSubmissions={(submissions?: number) =>
                  handleChange('additionalStudentSubmissions', submissions)
                }
              ></AdditionalSubmissions>
            </Flex>
            <Flex behavior={FlexBehavior.item} direction={FlexDirection.column}>
              <Penalties
                assignmentTotalPoints={3}
                penaltyOption={bulkEditFormData.penaltyOption}
                penaltyValue={bulkEditFormData.penaltyValue}
                onPenaltyOptionChange={(option: string) => handleChange('penaltyOption', option)}
                onPenaltyValueChange={(value?: number) => handleChange('penaltyValue', value)}
              ></Penalties>
            </Flex>
          </InputContainer>
        </StyledForm>
      </StyledCard>
    </div>
  );
};
