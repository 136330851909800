import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { BulkEditType, IHeaderStudentInfo, RestrictionLevel } from 'context/ExtensionsContextProvider/types';
import { getHeaderStudentInfo } from './apiHelpers';
import { LoaderComponent } from 'wa-ui-components';
import { Header } from 'facultyComponents/extensions/header/Header';
import { BulkEditExtensions } from 'facultyComponents/extensions/edit/BulkEditExtensions';

export const StudentAssignmentsPage: React.FC = () => {
  const { userId, sectionId } = useParams<{ userId: string; sectionId: string }>();

  const [isHeaderLoading, setHeaderLoading] = useState(true);
  const [studentInfo, setStudentInfo] = useState<IHeaderStudentInfo>();

  useEffect(() => {
    if (!sectionId || !userId) return;
    const fetchStudentInfo = async () => {
      try {
        const response = await getHeaderStudentInfo(userId, RestrictionLevel.SECTION, sectionId);
        setStudentInfo(response.data.result);
      } catch (error) {
        console.error('Failed to fetch student extensions:', error);
      }
    };
    fetchStudentInfo().finally(() => {
      setHeaderLoading(false);
    });
  }, [sectionId, userId]);

  return (
    <>
      {isHeaderLoading ? (
        <LoaderComponent />
      ) : (
        <>
          <Header student={studentInfo} sectionId={sectionId} />
          <BulkEditExtensions
            bulkEditType={BulkEditType.STUDENT}
            isTimedAssignment={true}
            isQuestionLevelSubmissionOverrideAvailable={true}
          />
          <BulkEditExtensions bulkEditType={BulkEditType.ASSIGNMENT} isTimedAssignment={true} />
          <BulkEditExtensions
            bulkEditType={BulkEditType.ASSIGNMENT}
            isTimedAssignment={true}
            isUnlimitedTimeAccommodation={true}
            isAllQuestionsHaveOverriddenSubmissions={true}
          />
        </>
      )}
    </>
  );
};
